import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./Pagination.module.scss";

interface Props {
  currentPage: number;
  totalPages: number;
  switchToPage: (arg: number) => void;
  oldestNewestVisable?: boolean;
}

function range(
  start: number,
  stop: number,
  step: number,
): Array<number> {
  const a = [start];
  let b = start;
  while (b < stop) {
    a.push((b += step || 1));
  }
  return a;
}

export function Pagination({
  currentPage,
  switchToPage,
  totalPages,
  oldestNewestVisable,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  let startPage;
  let endPage;

  if (totalPages < 10) {
    endPage = totalPages;
    startPage = 1;
  } else {
    startPage = currentPage - 4;
    endPage = currentPage + 5;
    if (startPage < 1) {
      endPage -= startPage - 1; // Pad the extra space to the right
      startPage = 1;
    }
    if (endPage > totalPages) {
      startPage += totalPages - endPage;
      endPage = totalPages;
    }
  }

  return <div className={style.pagination}>
    { oldestNewestVisable &&
      <button
        onClick={() => switchToPage(1)}
        disabled={currentPage < 2}
      >{t("Newest")}</button>
    }
    <button
      onClick={() => switchToPage(currentPage - 1)}
      disabled={currentPage < 2}
    >&laquo;</button>
    {range(startPage, endPage, 1).map((el) =>
      <button
        onClick={() => switchToPage(el)}
        data-current={el === currentPage}
      >{el.toFixed(0)}</button>
    )}
    <button
      onClick={() => switchToPage(currentPage + 1)}
      disabled={currentPage >= totalPages}
    >&raquo;</button>
    { oldestNewestVisable &&
      <button
        onClick={() => switchToPage(totalPages)}
        disabled={currentPage >= totalPages}
      >{t("Oldest")}</button>
    }
  </div>
}

export function usePageCalculation<T>(
  value: T[],
  itemsPerPage: number,
  initialPage = 1,
): {
    valuesToShow: T[];
    maxPages: number;
    currentPage: number;
    setCurrentPage: (arg0: number) => void;
  } {
  const [maxPages, setMaxPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);
  useEffect(() => {
    setMaxPages(Math.ceil(value.length / itemsPerPage));
  }, [itemsPerPage, value]);
  const valuesToShow = useMemo(
    () => value.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [value, itemsPerPage, currentPage],
  );

  return {
    valuesToShow, maxPages, currentPage, setCurrentPage,
  };
}
