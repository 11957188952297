import moment from "moment";
import { TFunction } from "react-i18next";
import { MessageResponse } from "../types/requesterTypes";
import { Materials } from "../types/types";
import { NodeTypes, ProbeTypes, TSensorTypes } from "../types/generated_types";

export function cleanUpDateTime(dtString: string | null | undefined): string {
  if (dtString && dtString !== "0000-00-00 00:00:00") {
    return moment.utc(dtString).local().format("YYYY-MM-DD HH:mm:ss");
  }
  return "0000-00-00 00:00:00";
}

// This function is used to check if an error was returned from an endpoint
export function isMessageResponse<T>(err: MessageResponse | T): err is MessageResponse {
  return err !== undefined && (err as MessageResponse).status !== undefined;
}

export function getProductName(input: NodeTypes, t: TFunction<string>): string {
  switch (input) {
    case NodeTypes.IMI:
      return t("Integration Box");
    case NodeTypes.CC4:
      return t("CC4 Box");
    case NodeTypes.IMR:
    case NodeTypes.IMRMk2:
      return t("Relay Box");
    case NodeTypes.IMS:
    case NodeTypes.IMSMk2:
      return t("Sensor Box");
    case NodeTypes.AD9:
      return t("Dehumidifier");
    default:
      return input;
  }
}

export function getProbeName(input: ProbeTypes | null, t: TFunction<string>): string {
  /* NOTE: Maintain together with back-end's PROBE_NAMES
   *       in backend/reports/helpers.py
   */
  switch (input) {
    case ProbeTypes.OHM:
      return t("Resistive");
    case ProbeTypes.RH:
      return t("RH/TEMP");
    case ProbeTypes.VOC:
      return t("IAQ");
    default:
      return t("Unknown");
  }
}

// Maintain together with `style_format()` in
// 'backend/reports/helpers.py'
export function roundData(
  latestData: string | null,
  sensorType: TSensorTypes,
  t: TFunction<string>,
  material?: Materials,  // unused since IMCS-730
): string {
  if (latestData === null) {
    return "--";
  } else {
    switch (sensorType) {
      case "PDV":
      case "WCT":
      case "GPK":
        return Number(latestData).toFixed(2);
      case "TMP":
      case "HUM":
      case "DWT":
        return Number(latestData).toFixed(1);
      case "WME":
        return Number(latestData).toFixed(1);
      case "HML":
        return Number(latestData).toFixed(0);
      case "PWR":
        return Number(latestData).toFixed(3);
      case "VOC":
      case "CO2":
      case "OHR":
        return Number(latestData).toFixed(0);
      case "STE":
      case "STE1":
      case "STE2":
      case "STE3":
      case "STE4":
        if (latestData === "0") {
          return t("OFF");
        } else {
          return t("ON");
        }
      case "MC":
        return Number(latestData).toFixed(1);
      case "OHM":
        return Number(latestData).toFixed(2);
      case "HEAT":
        if (latestData === "0") return t("Disabled");
        if (latestData === "1") return t("Enabled");
        return latestData;
      case "FMODE":
        if (latestData === "0") return t("Normal");
        if (latestData === "1") return t("Continuous");
        return latestData;
      case "SPEED":
        if (latestData === "0") return t("Slow");
        if (latestData === "1") return t("Normal");
        if (latestData === "2") return t("Night");
        return latestData;
      default:
        return Number(latestData).toFixed(1);
    }
  }
}

// Calculates moisture content value based on wood moisture equivalent value
// Formulae provided in IMCS-342
export function calculateMCValue(wmeValue: number, material: Materials) : number {
  /* NOTE: Maintain together with back-end's calculate_MC_value()
   *       in backend/reports/helpers.py
   */
  switch (material) {
    case Materials.WOOD:
      return wmeValue;
    case Materials.PLAS:
      return 0.176974 * wmeValue - 2.47171;
    case Materials.BRIC:
      return 0.374781 * wmeValue - 4.33092;
    case Materials.CEME:
      return 0.520614 * wmeValue - 5.64342;
    case Materials.SAND:
      return 0.302564 * wmeValue + 1.22821;
    case Materials.CONC:
      return 0.215187 * wmeValue + 1.44398;
    default:
      return 0;
  }
}

export function isNonChartableRow(sensorType: TSensorTypes) {
  return [
    TSensorTypes.OHR,
  ].includes(sensorType);
}