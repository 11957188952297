import React, { useState } from "react";
import { PortalModal } from "../PortalModal/PortalModal";
import PortalModalHeader from "../PortalModal/PortalModalHeader";
import { Trans, useTranslation } from "react-i18next";
import PortalModalContent from "../PortalModal/PortalModalContent";
import PortalModalFooter from "../PortalModal/PortalModalFooter";
import { DeviceNodeAD9, HeaterScheduleTime } from "../../types/types";
import { Requester } from "../../utils/Requester";
import { useChart } from "../../contexts/ChartDataContext";
import { CloseButton } from "../PortalModal/CloseButton";
import { ModalButton } from "../PortalModal/ModalButton";
import toast from "react-hot-toast";
import { QuietTimeSelector, TIME_UNITS_PER_DAY } from "./QuietTimeSelector";
import style from "./QuietModeScheduler.module.scss";
import addIcon from "./TimeRangeModal/add_icon.svg";
import ToggleSwitch from "../Misc/ToggleSwitch/ToggleSwitch";
import { TimeButton } from "./IconButton/TimeButton";

interface HeaterScheduleSelection {
  days: number,
  startTime: number,
  duration: number,
}

interface Props {
  node: DeviceNodeAD9
  disabled: boolean
}

export function HeaterConfigButton({
  node,
  disabled,
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const [isHeaterEnabled, setIsHeaterEnabled] = useState(node.ohtr);
  const [showSchedules, setShowSchedules] = useState(false);
  const [selections, setSelections] = useState<Map<number, HeaterScheduleSelection>>(new Map());

  const { refreshNodes } = useChart();
  const { t } = useTranslation();

  function handleOpen() {
    if (node.heater_schedules) {
      const map = new Map<number, HeaterScheduleSelection>();
      let index = 0;
      while (index < node.heater_schedules.length) {
        let item = node.heater_schedules[index];
        let nextItem = node.heater_schedules[index + 1];

        // determine if item can be merged with nextItem
        if (
          nextItem
          && nextItem.days === (((item.days << 1) & 127) | (item.days >> 6))
          && item.end_time / 600 === TIME_UNITS_PER_DAY
          && nextItem.start_time === 0
        ) {
          map.set(Date.now() + index, {
            days: item.days,
            startTime: item.start_time / 600,
            duration: (item.end_time - item.start_time + nextItem.end_time) / 600,
          });
          // increment index additional time since nextItem is merged and is to be skipped next iteration
          index++;
        } else {
          map.set(Date.now() + index, {
            days: item.days,
            startTime: item.start_time / 600,
            duration: (item.end_time - item.start_time) / 600,
          });
        }
        index++;
      }
      setSelections(map);
    }
    setIsHeaterEnabled(node.ohtr);
    setShowSchedules(!!(node.heater_schedules.length && node.timezone));
    setShowModal(true);
  }

  function handleSave() {
    let requestArray: HeaterScheduleTime[] = [];

    if (isHeaterEnabled && showSchedules) {
      Array.from(selections.values()).forEach((value) => {
        requestArray.push({
          start_time: value.startTime * 600,
          end_time: Math.min(value.startTime + value.duration, TIME_UNITS_PER_DAY) * 600,
          days: value.days,
        });

        // if duration reaches over end of the day add entry for next day
        if (value.startTime + value.duration > TIME_UNITS_PER_DAY) {
          requestArray.push({
            start_time: 0,
            end_time: (value.startTime + value.duration - TIME_UNITS_PER_DAY) * 600,
            // days rotated left by one
            days: ((value.days << 1) & 127) | (value.days >> 6),
          });
        }
      });
    }

    Requester.setHeaterConfig({
      id: node.id,
      ohtr: isHeaterEnabled,
      heater_schedules: requestArray
    })
      .then(() => {
        setShowModal(false);
        toast.success(t("Saved").toString());
        refreshNodes();
      })
      .catch(() => toast.error(t("Something went wrong").toString()));
  }

  return <>
    <TimeButton
      onClick={() => handleOpen()}
      on={
        node.ohtr &&
        node.heater_schedules.length > 0
      }
      disabled={disabled}
      title={t("Configure heater")}
    />
    <PortalModal
      isOpen={showModal}
      close={() => setShowModal(false)}
    >
      <PortalModalHeader
        title={t("Configure heater")}
        close={() => setShowModal(false)}
      />
      <PortalModalContent>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          <Trans>Enable heater</Trans>
          <ToggleSwitch
            checked={isHeaterEnabled}
            onChange={(checked) => {
              setIsHeaterEnabled(checked);
              if (!checked) {
                setShowSchedules(false);
              }
            }}
          />
        </div>
        {isHeaterEnabled && (
          <>
            <hr />
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem"
            }}>
              <Trans>Schedule exceptions</Trans>
              <div title={node.timezone ? undefined : t("Feature unavailable. Installation is missing geo-location.")}>
                <ToggleSwitch
                  disabled={!node.timezone}
                  checked={showSchedules}
                  onChange={(checked) => {
                    setShowSchedules(checked);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {isHeaterEnabled && showSchedules && (
          <>
            <div className={style.header}>
              <label>
                <Trans>Disable heater when:</Trans>
              </label>
              <label className={style.timezone}>
                <Trans>Times in {{ timezone: node.timezone?.join("/") }}</Trans>
              </label>
            </div>
            <br />
            <div className={style.list}>
              {Array.from(selections.entries()).map(([key, selection]) =>
                <div className={style["list-item-container"]} key={key}>
                  <QuietTimeSelector
                    days={selection.days}
                    startTime={selection.startTime}
                    duration={selection.duration}
                    helpMode={false}
                    onDelete={() => {
                      const map = new Map(selections);
                      map.delete(key);
                      setSelections(map);
                    }}
                    onSelectionChange={(days, startTime, duration) => {
                      const map = new Map(selections);
                      map.set(
                        key,
                        {
                          days: days,
                          startTime: startTime,
                          duration: duration,
                        }
                      );
                      setSelections(map);
                    }}
                    socketCount={0}
                    sockets={0}
                  />
                  <hr />
                </div>
              )}
              <button
                className={style["add-button"]}
                onClick={() => {
                  const map = new Map(selections);
                  map.set(
                    Date.now(),
                    {
                      days: 0,
                      startTime: 40,
                      duration: 60,
                    }
                  );
                  setSelections(map);
                }}
              >
                <img src={addIcon} alt="Add" />
                <Trans>Add Time</Trans>
              </button>
            </div>
          </>
        )}
      </PortalModalContent>
      <PortalModalFooter>
        <ModalButton
          onClick={handleSave}
        >
          {t("Submit")}
        </ModalButton>
        <CloseButton close={() => setShowModal(false)} />
      </PortalModalFooter>
    </PortalModal>
  </>
}