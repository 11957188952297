import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./TabsContainer.module.scss";

interface Props {
  children: ReactNode,
}

/**
 * Container that provides the parent styling necessary for `<TabLink>` elements.
 * It is recommended to only add `<TabLink>` elements as children to this
 * container (or other components that share behavior) and to have
 * `<ContentBox>` or a similar element directly after this container - this
 * container will slightly overlap the following container to create seamless
 * background between selected tab and following container.
 */
export function TabsContainer({
  children,
}: Props) {
  // Tabs container reference used for comparisons and to control scroll programmatically
  const tabsBar = useRef<HTMLDivElement>(null);
  const [showButtons, setShowButtons] = useState(false);

  // This useEffect ensures scroll buttons are visible when tabs no longer fit
  // in their container. Resize handler is created, attached to window and called
  // once to set the initial state.
  useEffect(() => {
    function handleResize() {
      if (tabsBar && tabsBar.current) {
        setShowButtons(tabsBar.current.scrollWidth > tabsBar.current.clientWidth);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  return <div className={styles.container}>
    { showButtons &&
      <button
        className={styles["scroll-button"]}
        onClick={() => {
          if (!(tabsBar && tabsBar.current)) return;
          tabsBar.current.scrollBy({left: -100, behavior: "smooth"});
        }}
      >
        <i className="fas fa-chevron-left"></i>
      </button>
    }
    <div
      ref={tabsBar}
      className={styles["tabs-container"]}
      style={{
        // Change container width to make space for buttons.
        // Fill parent entirely when buttons are not shown.
        width: showButtons ? "calc(100% - 4rem)" : "100%",
      }}
    >
      {children}
    </div>
    { showButtons &&
      <button
        className={styles["scroll-button"]}
        onClick={() => {
          if (!(tabsBar && tabsBar.current)) return;
          tabsBar.current.scrollBy({left: 100, behavior: "smooth"});
        }}
      >
        <i className="fas fa-chevron-right"></i>
      </button>
    }
  </div>
}