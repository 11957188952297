import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ChartTypes } from "../../types/types";
import { useChartDataType } from "../../hooks/UseChartDataType";
import { FilterBlock } from "../Table/FilterBlock";

interface Props {
  chartType: ChartTypes;
  chartTypeChange: (
    e: ChartTypes
  ) => void;
}

export function ChartTypeWrapper({
  chartType,
  chartTypeChange,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [, setTypes, options] = useChartDataType(chartType);

  useEffect(() => {
    setTypes(chartType);
  }, [chartType, setTypes]);
  return <FilterBlock
    label={t("Chart Type")}
  >
    <select
      className="custom-select"
      id="inputDropdownChartType"
      name="chartType"
      onChange={(e) => chartTypeChange(e.target.value as ChartTypes)}
      value={chartType}
    >
      {options.map((el) => (
        <option key={el.valueString} value={el.valueString}>
          {el.displayString}
        </option>
      ))}
    </select>
  </FilterBlock>
}
