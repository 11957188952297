import React, { ReactNode } from "react";
import styles from "./ModalButton.module.scss";

// alternate button styles, if no style specified its blue by default
export enum ButtonStyle {
  GRAY="GRAY",
  RED="RED",
  GREEN="GREEN",
}

interface Props {
  children: ReactNode;
  disabled?: boolean;
  buttonStyle?: ButtonStyle;
  onClick: () => void;
}

export function ModalButton({
  children,
  disabled,
  buttonStyle,
  onClick,
}:Props) {
  return <button
    className={styles.button}
    data-style={buttonStyle}
    disabled={disabled}
    onClick={() => onClick()}
  >
    {children}
  </button>
}