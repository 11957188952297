import React, { PropsWithChildren, ReactElement, useState } from "react";
import FileDownload from "js-file-download";
import moment from "moment";
import "moment-timezone";
import toast from "react-hot-toast";

import { useTranslation } from "react-i18next";
import { ReportQueryContainer } from "../../types/types";

import { Requester } from "../../utils/Requester";
import loadingImage from "../../images/loading-indicator.svg"

interface Props {
  queryContainer: Array<ReportQueryContainer>;
  hasData: boolean;
}

export function GenerateReport({
  queryContainer,
  hasData,
}: PropsWithChildren<Props>): ReactElement {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const loadingIndicator = (
    <div className="d-flex mt-3 p-2">
      <img 
        className="mr-2"
        src={loadingImage}
        alt=""
        />
      <p>{t("Loading")}</p>
    </div>
  )

  const generatorButton = (
    <button
      className="mt-3"
      type="button"
      id="grenerate-report-pdf"
      disabled={!hasData}
      onClick={() => {;
        setLoading(true);
        const date: Date = new Date();
        void Requester.downloadGenerateReport({
          data: queryContainer,
          // Guess the users timezone --> e.g "Europe/Riga"
          timezone: moment.tz.guess(),
        })
          .then((response) => {
            FileDownload(
              response.data,
              `${date.getDate()}-${
                date.getMonth() + 1
              }-${date.getFullYear()}_report.zip`,
            );
            // NOTE: TypeScript does not allow passing `t(..)` as a direct
            // parameter to the toaster.
            const successMessage = t("Downloading!");
            toast.success(successMessage);
            setLoading(false);
          })
          .catch(() => {
            const errorMessage = t("Unexpected error when generating report!");
            toast.error(errorMessage);
          })
          .finally(() => {
            setLoading(false);
          });
      }}
    >
      {t("Generate Report")}
    </button>
  )
  return (
    <div> 
      {loading ? loadingIndicator : generatorButton}
    </div>
  );
}
