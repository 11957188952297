import React, { MouseEventHandler } from "react"
import styles from "./TabLink.module.scss"

interface Props {
  title: string
  selected: boolean
  onClick: MouseEventHandler
}

/**
 * Tab selector intended to be used in `TabsContainer`. Accepts `onClick` listener
 * for implementation of custom tab selection logic. See `TabLink` if navigation
 * to a URL is needed.
 */
export function TabButton({
  title,
  selected,
  onClick,
}: Props) {
  return <div 
    className={styles.tab}
    data-selected={selected}
    onClick={onClick}
  >
    {title}
  </div>
}