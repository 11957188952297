import React, { ReactElement, useState } from "react"
import styles from "./GridTable.module.scss"

import { ReactNode } from "react"
import { TFunction, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

/**
 * Table colum interface
 * 
 * @param key Key of the column, used for lists in React
 * @param width Will be used to build grid column template, `1fr` will be set if not provided
 * @param getTitle Function that must return column title
 * @param getCell Function that must return cell contents
 * @param sort Sort function for sorting data based on this column.
 * When sort button is pressed `sort()` function for the table will be called and
 * column will be provided to it where this function can be used to sort the data
 */
export interface TableColumn<T, K = void> {
  key: string
  width?: string
  getTitle: (t: TFunction) => string
  getCell: (data: T, cellInterface?: K) => ReactNode
  sort?: (data: T[], order: number) => T[] 
}

interface Props<T, K = void> {
  data: T[]
  columns: TableColumn<T, K>[]
  getRowKey: (data: T) => string
  getRowURL?: (data: T) => string | undefined
  rowDisabled?: (data: T) => boolean
  onRowSelect?: (data: T) => void
  sort?: (column: TableColumn<T, K>) => void
  cellInterface?: K,
  sortColumn?: TableColumn<T, K>
  sortOrder?: number // 1: ascending, -1: descending, 0: none
  minColumnWidth?: string
}

function addLinkWrapper(cell: ReactNode, link: string | undefined, key: string) {
  if (link) return <Link key={key} to={link}>{cell}</Link>
  return cell
}

/**
 * Table component based on CSS grid
 * 
 * @param data Array of data to be displayed in the table
 * @param columns Array of columns that extend or use `TableColumn` interface
 * @param getRowKey Key of the row, used for lists in React
 * @param getRowURL If provided and does not return `undefined` then table row will also act as a link
 * @param getDisabled If provided and returns `true` then table row will be visually grayed out
 * @param onRowSelect Rows can be selected if implemented, called when a row is selected
 * @param sort Called when Sort button is pressed. Column will be its first parameter and it is
 * expected to use its `sort()` function to sort the data. If this function is implemented then
 * sort button will appear for any columns that have `sort()` function implemented.
 * @param sortColumn This and `sortOrder` are used to display correct sort icon, default icon displayed if not provided
 * @param sortOrder This and `sortcolumn` are used to display correct sort icon, default icon displayed if not provided
 */
export function GridTable<T, K = void>({
  data,
  columns,
  getRowKey,
  getRowURL,
  rowDisabled,
  onRowSelect,
  sort,
  cellInterface,
  sortColumn,
  sortOrder,
  minColumnWidth,
}: Props<T, K>) {
  const { t } = useTranslation()
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const gridTemplate = columns.map(column => {
    if (column.width?.endsWith("fr")) {
      return `minmax(${minColumnWidth || "20rem"}, ${column.width})`
    }
    return column.width ?? `minmax(${minColumnWidth || "20rem"}, 1fr)`
  }).join(" ")

  // SortOrder icon can be set if sort order information is provided
  // This will be left undefined otherwise and also if sort order is 0 in which case default icon will be used
  let sortIcon: ReactElement;
  if (sortOrder && sortOrder > 0) sortIcon = <i className="fas fa-sort-up"></i>
  if (sortOrder && sortOrder < 0) sortIcon = <i className="fas fa-sort-down"></i>

  return <div
    className={`${styles.grid}`}
    style={{gridTemplateColumns: gridTemplate}}
    onMouseLeave={() => setHoverIndex(-1)}
  >
    {columns.map(column =>
      <div
        key={column.key}
        className={`${styles.cell} ${styles.header}`}
      >
        <span>{column.getTitle(t)}</span>
        {sort && column.sort && // render sort button if necessary sort functions are provided
          <button onClick={() => sort(column)}>
            { // render sort order icon if provided, otherwise render default icon
              (column === sortColumn && sortIcon) || <i className="fas fa-sort"></i>
            }
          </button>
        }
      </div>
    )}
    {data.map((item, index) =>
      columns.map(column =>
        addLinkWrapper(
          <div
            key={`${column.key}_${getRowKey(item)}`}
            className={styles.cell}
            data-gray-row={rowDisabled && rowDisabled(item)}
            data-even-row={(index+1) % 2 === 0}
            data-hover-row={index === hoverIndex}
            data-selected={index === selectedIndex}
            onMouseEnter={() => hoverIndex!== index && setHoverIndex(index)}
            onClick={() => {
              if (!onRowSelect) return
              onRowSelect(item)
              setSelectedIndex(index)
            }}
          >{column.getCell(item, cellInterface)}</div>,
          getRowURL && getRowURL(item),
          `${column.key}_${getRowKey(item)}`,
        )
      )
    )}
  </div>
}