import React, { ReactNode } from "react";
import styles from "./PageTitleBox.module.scss";

interface Props {
  children: ReactNode;
  rightContainer: ReactNode;
}

// This, <ContentBox> and <PageWrapper> are 3 containers were created as a
// replacement for <PaddedPageWrapper> splitting responsibilities allowing for
// more flexibility and reusability

/**
 * Title container which displays the title contents centered in page, separated
 * from the rest of the page by a horizontal ruler.
 * It also has a container for button placement at its right side, contents of
 * which provided as a prop.
 *
 * @param rightContainer right side container contents (JSX)
 */
export function PageTitleBox({
  children,
  rightContainer,
}: Props) {
  return <>
    <div className={styles.container}>
      <div className={styles.left}>{children}</div>
      <div className={styles.right}>{rightContainer}</div>
    </div>
    <hr className={styles.separator}/>
  </>
}