import React, { ReactElement, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { InstallationInfoPatch } from "../../types/requesterTypes";
import {
  InstallationInfo,
  InstallationSamplingRates,
} from "../../types/types";
import { Requester } from "../../utils/Requester";
import PortalModalHeader from "../PortalModal/PortalModalHeader";
import PortalModalContent from "../PortalModal/PortalModalContent";
import PortalModalFooter from "../PortalModal/PortalModalFooter";
import { ModalButton } from "../PortalModal/ModalButton";

interface Props {
  installation: InstallationInfo;
  device_id: string;
  onUpdate: () => void;
  close: () => void;
}

type SampleRates = { value: InstallationSamplingRates; label: string };

export function InstallationConfigModal({
  installation,
  device_id,
  onUpdate,
  close,
}: Props): ReactElement {
  const { t } = useTranslation();

  const options: Array<SampleRates> = [
    {
      value: InstallationSamplingRates.RATE1,
      label: `5 ${t("minutes")} (${t("additional cost applies")})`,
    },
    {
      value: InstallationSamplingRates.RATE2,
      label: `15 ${t("minutes")} (${t("additional cost applies")})`,
    },
    {
      value: InstallationSamplingRates.RATE3,
      label: `1 ${t("hour")}`,
    },
  ];

  const samplingRate = installation.sampling_rate;
  const initialRate = options.find((a) => a.value === samplingRate)
    || {
      value: samplingRate,
      label: `${t("Custom interval")}: ${Math.floor(samplingRate / 60)} min ${samplingRate % 60} sec`,
    };

  const [newRate, setRate] = useState<SampleRates>(initialRate);

  return <>
    <PortalModalHeader
      title={t("Configure {{installation_name}} ({{installation_id}})", {
        installation_name: installation.name,
        installation_id: device_id })}
      close={close}
    />
    <PortalModalContent>
      <h3 className="mt-2">{t("Sampling interval")}</h3>
      <Select
        closeMenuOnSelect
        value={newRate}
        options={options}
        onChange={(newRateValue) => {
          setRate(newRateValue as SampleRates);
        }}
      />
    </PortalModalContent>
    <PortalModalFooter>
      <ModalButton
        onClick={() => {
          const patchObject: InstallationInfoPatch = {
            installationID: installation.pk,
            sampling_rate: newRate.value,
          };

          void Requester.patchInstallationInfo(patchObject)
            .then(() => toast.success(
              t("Installation settings updated!").toString()
            ))
            .then(() => onUpdate())
            .catch(() => toast.error("Failed updating settings!")); // There is no backend written in the case that saving settings fails, so this part is an unreachable.

          close();
        }}
      >
        {t("Save")}
      </ModalButton>
    </PortalModalFooter>
  </>
}
