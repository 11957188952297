import React, { ReactNode, useRef } from "react"
import { useSpring, animated } from "react-spring";

interface Props {
  children: ReactNode
  show: boolean
  fade?: boolean
}

/**
 * Animated expandable container
 * 
 * @param show Set `true` when contents need to be shown
 */
export function ExpandableContainer({
  children,
  show,
  fade,
}: Props) {
  const container = useRef<HTMLDivElement>(null)
  const animationStyles = useSpring({
    height: show ? container.current?.scrollHeight || 0 : 0,
    opacity: show ? 1 : fade ? 0 : 1,
    overflow: "hidden",
  });

  return <animated.div
    style={animationStyles}
  >
    <div ref={container}>
      {children}
    </div>
  </animated.div>
}