import React, { PureComponent, CSSProperties } from "react";
import AmCharts from "@amcharts/amcharts3-react";
import { TFunction } from "react-i18next";

import { TranslateVisualSensorType } from "./Misc/TranslateVisualSensorType";
import { cleanUpDateTime, roundData } from "../utils/utility";
import {
  SensorColours,
  SensorData,
  DateTimeHash,
  Types,
} from "../types/types";
import { TSensorTypes, getUnit } from "../types/generated_types";

import sassStyle from "../containers/SensorData.module.scss";
import { NoData } from "./Misc/NoData";

interface Props {
  data: Array<SensorData>;
  selSensorTypes: Array<TSensorTypes>;
  secondaryAxis: TSensorTypes | undefined;
  // colors contains the front-end name of selected sensor (key) and the color in hex (value)
  colors: SensorColours;
  constantTime: boolean;
  loading: boolean;
  setLoading: (val: boolean) => void;
  children: JSX.Element;
  style?: CSSProperties;
  t: TFunction<string>;
  yMaxMain?: number;
  yMinMain?: number;
  yMaxSecond?: number;
  yMinSecond?: number;
  loadAllData: () => void;
  isThisReport?: boolean;
}

// Removes the numbers next to the sensor types
export function cleanSensorType(val: string): string {
  const tempVal = val.split("_");
  return tempVal[0];
}

export function sensorToAxisLabel(type: TSensorTypes): string {
  return `${TranslateVisualSensorType(type)}(${getUnit(type)})`;
}

export class ChartData extends PureComponent<Props> {
  renderChart(): JSX.Element {
    // FIXME: This function is not covered by tests because most of this is
    // legacy code from dashboard V2. A better option would be to rewrite all
    // of this with amcharts 4 instead of trying to figure out what it actually
    // does and then test it.
    const { data, constantTime, colors, selSensorTypes, secondaryAxis, yMaxMain, yMinMain, yMaxSecond, yMinSecond } = this.props;
    const { isThisReport } = this.props;
    const { t } = this.props;
    const dateTimeHash: DateTimeHash = {};

    const types: Types = {};
    data.forEach((item) => {
      const captDT = item.captured_datetime;
      const HasProperty = dateTimeHash.hasOwnProperty.call(
        dateTimeHash,
        captDT,
      );

      if (!HasProperty) {
        dateTimeHash[captDT] = {};
      }
      const suffix = item.distinct_chart_line;
      types[suffix] = 1;
      const type = TranslateVisualSensorType(item.sensor_type);

      dateTimeHash[captDT][`sensor_type${suffix}`] = type;
      dateTimeHash[captDT][`node_name${suffix}`] = item.node_name;
      dateTimeHash[captDT][`node_id${suffix}`] = item.node_id.toString();
      dateTimeHash[captDT][`nd${suffix}`] = item.nd.toString();
      dateTimeHash[captDT][`value${suffix}`] = item.value;
      dateTimeHash[captDT].captured_datetime = captDT;
    });

    const typesKeys = Object.keys(types);


    const arr = selSensorTypes
      .filter((sens) => sens !== secondaryAxis)
      .map((item) => sensorToAxisLabel(item));

    // This is for the labels on the primary Y axis.
    const primaryAxisTitle = arr.join(" ");

    const valueAxes = [];

    valueAxes.push({
      id: "MainAxis",
      title: primaryAxisTitle,
      position: "left",
      titleFontSize: (isThisReport) ? 16 : 12,
      titleBold: true,
      gridAlpha: 0.15,
      minimum: yMinMain,
      maximum: yMaxMain,
    });

    if (secondaryAxis !== undefined) {
      const secondaryAxisTitle = sensorToAxisLabel(secondaryAxis);
      valueAxes.push({
        id: "SecondaryAxis",
        title: secondaryAxisTitle,
        position: "right",
        titleFontSize: (isThisReport) ? 16 : 12,
        titleBold: true,
        gridAlpha: 0,
        minimum: yMinSecond,
        maximum: yMaxSecond,
      });
    }

    const chartData = Object.keys(dateTimeHash)
      .map((datetime) => dateTimeHash[datetime]);

    const graphs = typesKeys.map((type) => ({
      balloonFunction(graphDataItem: any, graph: any) {
        if (graphDataItem.values.value === undefined)
          return "";
        const nodeName = graphDataItem.dataContext[`node_name${type}`];
        const nd = graphDataItem.dataContext[`nd${type}`];
        const sensorType = graphDataItem.dataContext[`sensor_type${type}`];
        let modifiedSensorType = sensorType;
        // STE{n} types get 'unconverted' from `TranslateVisualSensorTypes`
        // for `roundData()` switch case
        if (parseInt(sensorType)) {
          modifiedSensorType = `STE${sensorType}`
        }
        const value = roundData(graphDataItem.values.value.toString(), modifiedSensorType, t);
        const date = graphDataItem.dataContext["captured_datetime"];

        return `
          ${t("Name")}: ${nodeName} <br>
          ${t("ID")}: ${nd} <br> 
          ${t("Value")}: ${value}<br> 
          ${t("Type")}: ${sensorType}<br>  
          ${t("Collecting date")}: ${date} <br>
        `;
      },
      lineColor: colors[type],
      valueAxis: String(secondaryAxis) === cleanSensorType(type) ? "SecondaryAxis" : "MainAxis",
      bulletSize: 10,
      fillAlphas: 0,
      type: type.indexOf("TM") >= 0 ? "smoothedLine" : "smoothedLine",
      bullet: type.indexOf("TM") >= 0 ? "bubble" : "diamond",
      bulletAlpha: type.indexOf("TM") >= 0 ? "1" : "1",
      labelPosition: "right",
      valueField: `value${type}`,
      fontSize: 13,
      columnWidth: 0.5,
      clustered: false,
    }));

    const newestDataEntry = data.reduce((prev, curr) => {
      const lhsDate = new Date(prev.captured_datetime);
      const rhsDate = new Date(curr.captured_datetime);
      return (lhsDate > rhsDate) ? prev : curr;
    });

    const tempDate = cleanUpDateTime(newestDataEntry.captured_datetime);
    if (tempDate !== null) {
      chartData.push({
        node_id: "0",
        node_name: "0",
        nd: "0",
        sensor_type: "0",
        captured_datetime: tempDate,
        value: "0",
        node_id2: "0",
        node_name2: "0",
        nd2: "0",
        sensor_type2: "0",
        captured_datetime2: tempDate,
        value2: "0",
      });
    }

    graphs.push({
      balloonFunction: () => "",
      lineColor: "#fff",
      valueAxis: "MainAxis",
      bulletSize: 10,
      fillAlphas: 0,
      type: "smoothedLine",
      bullet: "round",
      bulletAlpha: "",
      labelPosition: "right",
      valueField: "value",
      fontSize: 13,
      columnWidth: 0.5,
      clustered: false,
    });

    if (secondaryAxis !== undefined) {
      graphs.push({
        balloonFunction: () => "",
        lineColor: "#fff",
        valueAxis: "SecondaryAxis",
        bulletSize: 10,
        fillAlphas: 0,
        type: "smoothedLine",
        bullet: "round",
        bulletAlpha: "0",
        labelPosition: "right",
        valueField: "value2",
        fontSize: 13,
        columnWidth: 0.5,
        clustered: false,
      });
    }

    const chartPdf = (
      <AmCharts.React
        className={sassStyle.amchart}
        options={{
          type: "serial",
          theme: "light",
          dataProvider: chartData,
          valueAxes,
          graphs,
          fontSize: 13,
          categoryField: "captured_datetime",
          chartCursor: {
            cursorPosition: "mouse",
            cursorColor: "#ffa500",
            valueLineBalloonEnabled: true,
            valueLineEnabled: true,
          },
          dataDateFormat: "YYYY-MM-DD JJ:NN:SS",
          categoryAxis: {
            labelRotation: 75,
            minorGridEnabled: true,
            parseDates: true,
            minPeriod: "ss",
            equalSpacing: !constantTime,
            markPeriodChange: false,
            fontSize: 13,
            dateFormats: [
              {
                period: "fff",
                format: "YYYY-MM-DD\nJJ:NN:SS",
              },
              {
                period: "ss",
                format: "YYYY-MM-DD\nJJ:NN:SS",
              },
              {
                period: "mm",
                format: "YYYY-MM-DD\nJJ:NN:SS",
              },
              {
                period: "hh",
                format: "YYYY-MM-DD\nJJ:NN:SS",
              },
              {
                period: "DD",
                format: "YYYY-MM-DD\nJJ:NN:SS",
              },
              {
                period: "WW",
                format: "YYYY-MM-DD\nJJ:NN:SS",
              },
              {
                period: "MM",
                format: "YYYY-MM-DD\nJJ:NN:SS",
              },
              {
                period: "YYYY",
                format: "YYYY-MM-DD\nJJ:NN:SS",
              },
            ],
          },
          responsive: {
            enabled: true,
          },
          valueScrollbar: {
            enabled: secondaryAxis === undefined && !isThisReport,
            backgroundAlpha: 0.6,
            backgroundColor: "#DAEC2A",
            selectedBackgroundAlpha: 1,
            selectedBackgroundColor: "#BCCF00",
          },
          export: {
            enabled: true,
            menu: [],
          },
          legend: {
            enabled: false,
          },
        }}
      />
    );
    return chartPdf;
  };

  render(): React.ReactElement {
    const {
      data, loading, isThisReport, setLoading, children, loadAllData
    } = this.props;

    const loadSensors = () => {
      setLoading(true);
    }

    if (!loading) {
      if (data.length > 0) {
        return this.renderChart();
      }
      if (isThisReport) return <></>
      return (
        <NoData loadAllData={loadAllData} loadSensors={loadSensors}  />
      );
    }

    return children;
  }
}
