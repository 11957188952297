import React, { ReactNode } from "react"
import styles from "./InfoBanner.module.scss"

export enum BannerStyle {
  WARNING="warning",
  ERROR="error",
}

interface Props {
  children: ReactNode
  style: BannerStyle
  small?: boolean
}

export function InfoBanner({
  children,
  style,
  small,
}: Props) {
  return <div
    className={styles.banner}
    data-style={style}
    data-small={small}
  >{children}</div>
}