import React from "react";
import { useTranslation, Trans, TFunction } from "react-i18next";
import { EmissionsData, EnergyData } from "../types/types";
import { getProductName } from "../utils/utility";

import style from './EnergyTable.module.scss';
import { GridTable, TableColumn } from "../components/Table/GridTable";

interface Props {
  co2?: EmissionsData;
  tableData:Array<EnergyData>
}

interface Column extends TableColumn<EnergyData, TFunction> {}

const columns: Column[] = [
  {
    key: "type",
    getTitle: t => t("Type"),
    getCell: (data, t) => t && <b>{getProductName(data.type, t)}</b>,
  },
  {
    key: "id",
    getTitle: t => t("ID"),
    getCell: data => data.id7,
  },
  {
    key: "name",
    getTitle: t => t("Name"),
    getCell: data => data.name,
  },
  {
    key: "start",
    getTitle: t => t("Start"),
    getCell: data => formatValue(data.start),
  },
  {
    key: "end",
    getTitle: t => t("End"),
    getCell: data => formatValue(data.end),
  },
  {
    key: "total",
    getTitle: t => t("Total"),
    getCell: data => formatValue(data.total),
  },
]

const formatValue = (value: number | null): string => {
  if (value === null) return "—";
  return `${value.toFixed(2)} kWh`;
};

export function EnergyTable({
  co2,
  tableData,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const total = tableData.reduce((prev, curr) => prev + (curr.total || 0), 0);
  const co2_data = co2 && {...co2, emissions: co2.intensity * total};

  return <div style={{overflowX: "auto"}}>
    <GridTable
      data={tableData}
      columns={columns}
      getRowKey={data => data.id7.toString()}
      minColumnWidth="8rem"
      cellInterface={t}
    />
    <div style={{
      display: "grid",
      gridTemplateColumns: "minmax(32rem, 4fr) minmax(8rem, 1fr) minmax(8rem, 1fr)",
    }}>
      <div></div>
      <div className={`${style.summary} ${style.separator}`}>{t("Total kWh")}</div>
      <div className={`${style.summary} ${style.separator}`}>{`${total.toFixed(2)} kWh`}</div>

      { co2_data &&
        <>
          <div></div>
          <div className={style.summary} style={{"fontSize": "10px"}}>
            <span style={{"fontSize": "14px"}}>
            <Trans>Estimated CO₂ emissions</Trans>
            </span>
            <a href={co2_data.url}>
              <Trans>
                Using {{entity: co2_data.entity}} data
                published in {{year: co2_data.year}}
              </Trans>
            </a>
          </div>
          <div className={style.summary} style={{justifyContent: "flex-start"}}>
            {co2_data.emissions.toFixed(2)} kg
          </div>
        </>
      }
    </div>
  </div>
}
