import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "../Misc/Pagination";
import { SpinnyThingy } from "../Misc/SpinnyThingy";
import { DatePickerWrapper } from "../ChartTypePicker/DatePickerWrapper";
import { ChartTypes, DeviceNode, IndividualSensorData } from "../../types/types";
import { calculateMCValue, cleanUpDateTime, roundData } from "../../utils/utility";
import { TranslateVisualSensorType } from "../Misc/TranslateVisualSensorType";
import { useChart } from "../../contexts/ChartDataContext";
import { Requester } from "../../utils/Requester";
import PortalModalHeader from "../PortalModal/PortalModalHeader";
import PortalModalContent from "../PortalModal/PortalModalContent";
import PortalModalFooter from "../PortalModal/PortalModalFooter";
import { CloseButton } from "../PortalModal/CloseButton";
import { TSensorTypes, getUnit } from "../../types/generated_types";

interface Props {
  node: DeviceNode,
  sensorType: TSensorTypes,
  close: () => void,
}

export function ModalSensorData({
  node,
  sensorType,
  close,
}: Props): React.ReactElement {
  const { t } = useTranslation();

  const { materialMap } = useChart();

  const [modalSensorDataSpinnyThingy, setModalSensorDataSpinnyThingy] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [endDate, setEndDate] = useState<Date>();
  const [startDate, setStartDate] = useState<Date>();
  const [maxPages, setMaxPages] = useState(1);
  const [modalData, setModalData] = useState<Array<IndividualSensorData>>([]);

  const switchToPage = useCallback(newPage => {
    setCurrentPage(newPage);
    setModalSensorDataSpinnyThingy(true);
    Requester.getIndividualSensor({
      id: node.id,
      count: 10, // Number of entries to be displayed per page
      page: newPage,
      // If MC then get WME data instead, MC will be calculated from WME data
      type: sensorType === TSensorTypes.MC ? TSensorTypes.WME : sensorType,
      startDate,
      endDate,
    })
    .then(result => {
      setModalSensorDataSpinnyThingy(false);
      setModalData(result.data);
      // max calculated by backend based on "count" property provided in request
      setMaxPages(result.total_pages);
    })
  }, [node.id, sensorType, startDate, endDate])

  useEffect(() => {
    switchToPage(1);
  },
  [startDate, endDate, switchToPage]);

  // If being rendered with any data at all
  let tableWithData;
  if (!modalSensorDataSpinnyThingy) {
    if (modalData.length) {
      const formattedData = modalData.map((el) => {
        const value = sensorType === TSensorTypes.MC 
        ? calculateMCValue(Number.parseFloat(el.value), materialMap.get(node.local_id)!).toString()
        : el.value
        return <tr className="modal-sensor-data-row" key={el.id}>
          <td>{el.page_idx}</td>
          <td>
            {roundData(value, el.sensor_type, t)}
            {" "}
            {getUnit(el.sensor_type)}
          </td>
          {/* Transforming sensor type to its display name */}
          <td>{TranslateVisualSensorType(el.sensor_type)}</td>
          <td>{cleanUpDateTime(el.captured_datetime)}</td>
        </tr>
      });

      const pagination = (
        <Pagination
          key="pagination"
          currentPage={currentPage}
          totalPages={maxPages}
          switchToPage={(page: number) => switchToPage(page)}
          oldestNewestVisable
        />
      );

      tableWithData = (
        <>
          <table className="table table-striped table-bordered table-hover table-sm">
            <thead>
              <tr key="modal-head">
                <th scope="col">#</th>
                <th scope="col">{t("Value")}</th>
                <th scope="col">{t("Sensor Type")}</th>
                <th scope="col">{t("Captured Date Time")}</th>
              </tr>
            </thead>
            <tbody>{formattedData}</tbody>
          </table>
          {pagination}
        </>
      );
    } else {
      tableWithData = t("NO DATA");
    }
  } else {
    // TODO get the spinny thingy from props.children
    tableWithData = <SpinnyThingy />;
  }

  return <>
    <PortalModalHeader
      close={close}
      title={`${t("Sensor Data for")} ${TranslateVisualSensorType(sensorType)} ${node.sensor_name}`}
    />
    <PortalModalContent>
      {tableWithData}
    </PortalModalContent>
    <PortalModalFooter>
      <div className="col"
      > 
        <DatePickerWrapper
          label="Start"
          chartType={ChartTypes.Daily}
          selected={startDate}
          onChange={date => setStartDate(date)}
          maxDate={endDate}
          startDate={startDate}
          endDate={endDate}
          selectsStart
          // minDate={endDate}
        />
      </div>
      <div className="col">
        <DatePickerWrapper
          label="End"
          chartType={ChartTypes.Daily}
          selected={endDate}
          onChange={date => setEndDate(date)}
          // maxDate={maxDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          selectsEnd
        />
      </div>
      <CloseButton close={close} />
    </PortalModalFooter>
  </>
}
