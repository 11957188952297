/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {
  useState, useMemo, useEffect, useCallback,
} from "react";

import { Trans, useTranslation } from "react-i18next";
import { ChartTypePickerWidget } from "../components/ChartTypePicker/ChartTypePickerWidget";
import { EnergyTable } from "./EnergyTable";
import { DisplayChart } from "./DisplayChart";
import { Requester } from "../utils/Requester";
import {
  generateQueryParamString,
  TWO_WEEK_MILLISECONDS,
} from "../hooks/UseChartGetParams";
import { SensorCheckboxContainer } from "./SensorCheckboxContainer";

import style from "./SensorData.module.scss";
import { useChart } from "../contexts/ChartDataContext";
import { GenerateReport } from "../components/Misc/GenerateReport";
import { ExpandableButtonContainer } from "./ExpandableButtonContainer";
import { ChartConfigurator } from "../components/ChartTypePicker/ChartConfigurator";
import { InfoText } from "../components/Misc/InfoText";
import { Button } from "../components/Misc/Buttons/Button";
import { ContentBox } from "../components/Misc/ContentBox";
import { TabsContainer } from "../components/Misc/TabsNavigation/TabsContainer";
import { TabButton } from "../components/Misc/TabsNavigation/TabButton";
import { cleanUpDateTime } from "../utils/utility";


export function ChartDataManager(): React.ReactElement {
  const chart = useChart();
  const { t } = useTranslation();
  const updateDefaultSensors = () => {
    Requester.postDefaultSelectedSensors(chart.chartParams.nodes || {});
  };
  const [allDataTab, setAllDataTab] = useState(true);
  const [chartLoading, setChartLoading] = useState<boolean>(true);

  interface DateRange {
    start: Date,
    end: Date
  }
  const [installationDateRange, setInstallationDateRange] = useState<DateRange>({
    start: new Date(), 
    end: new Date()
  }) 

  const fullrange = (chart.chartParams.x1 <= installationDateRange.start
    && chart.chartParams.x2 >= installationDateRange.end);

  const reportGeneratorQuery = useMemo(
    () =>
      generateQueryParamString(
        chart.chartParams,
        true,
        true,
      ),
    [chart.chartParams],
  );

  useEffect(() => 
    void Requester.getInstallMaxRange({
      installationID: chart.installation.pk,
    }).then((res) => {
      setInstallationDateRange({
        start: new Date(res.data.min_time),
        end: new Date(res.data.max_time),
      });
    }), [chart.installation],
  );

  const handleChartLoading = useCallback((val) => {
    setChartLoading(val);
  }, [])

  return (
    <>
      <div className={style["chart-view"]}>
        <DisplayChart
          selSensorTypes={chart.selSensorTypes}
          secondaryAxis={chart.secondaryAxis}
          forcedTimezone={chart.chartParams.timezone}
          nodes={chart.nodes}
          noNodesAvailable={chart.noNodesAvailable}
          constantTime={chart.chartParams.linearData}
          startDate={chart.chartParams.x1}
          endDate={chart.chartParams.x2}
          chartType={chart.chartParams.chartType}
          loading={chartLoading}
          setLoading={handleChartLoading}
          yMaxMain={chart.yMaxMain}
          yMinMain={chart.yMinMain}
          yMaxSecond={chart.yMaxSecond}
          yMinSecond={chart.yMinSecond}
          mcList={chart.materialMap}
        />
        <div className={style.sidebar}>
          <ChartTypePickerWidget
            chartType={chart.chartParams.chartType}
            chartTypeChange={chart.updateChartType}
            startDate={chart.chartParams.x1}
            endDate={chart.chartParams.x2}
            constantTime={chart.chartParams.linearData}
            visibleDate={chart.chartParams.visibleDates}
            alterDateEnd={(x2) => {
              chart.loadDataRange(chart.chartParams.x1, x2, false, true);
            }}
            alterDateStart={(x1) => {
              chart.loadDataRange(x1, chart.chartParams.x2, false, true);
            }}
            toggleConstantTime={() =>
              chart.updateLinearTime(!chart.chartParams.linearData)
            }
          />
          <hr style={{margin: "0px"}}/>
          <Button
            onClick={() => {
              setChartLoading(true);
              if (chart.chartParams.allData) {
                chart.loadDataRange(
                  new Date(new Date().getTime() - TWO_WEEK_MILLISECONDS),
                  new Date(),
                  false,
                  false,
                );
              } else {
                void Requester.getInstallMaxRange({
                  installationID: chart.installation.pk,
                }).then((res) => {
                  chart.loadDataRange(
                    new Date(res.data.min_time),
                    new Date(res.data.max_time),
                    true,
                    false,
                  );
                });
              }
            }}
          >
            {chart.chartParams.allData
              ? t("Load 2 week data")
              : t("Load all data")}
          </Button>
          <ExpandableButtonContainer 
            title={t("Configure Chart")}
          >
            <ChartConfigurator
              selSensorTypes={chart.selSensorTypes}
              secondaryAxis={chart.secondaryAxis}
              yMaxMain={chart.yMaxMain}
              yMinMain={chart.yMinMain}
              yMaxSecond={chart.yMaxSecond}
              yMinSecond={chart.yMinSecond}
              updateChartConfig={chart.updateChartConfig}
            />
          </ExpandableButtonContainer>
          <Button
            onClick={updateDefaultSensors}
          >
            {t("Save Default Chart")}
          </Button>
          <ExpandableButtonContainer
            title={t("Generate Report")}
          > 
            <p className="mt-4 mb-4 mr-2 ml-2">
              <Trans>
                Report will be created reflecting the currently visible
                data range and the selected sensors.
              </Trans>
            </p>
            {chart.hasData && !fullrange && (
              <InfoText>
                {t("Selected date range doesn't cover entire runtime")}
              </InfoText>
            )}
            {chart.hasData ||
              <InfoText>
                {t("Selected data range must contain data to generate a report")}
              </InfoText>
            }
            <GenerateReport
              queryContainer={[
                {
                  installation:
                    chart.installation.pk.toString(),
                  chartParams: reportGeneratorQuery,
                  materials: Object.fromEntries(chart.materialMap),
                },
              ]}
              hasData = {chart.hasData}
            />
          </ExpandableButtonContainer>
          <ExpandableButtonContainer
            title={t("Select/Deselect Type")}
          >
            <SensorCheckboxContainer
              nodes={chart.nodes}
              sensorToggle={chart.toggleAllSensorTypes}
              />
          </ExpandableButtonContainer>
        </div>
      </div>
      <br />
      {chart.energyData.length > 0 &&
        <>
          <TabsContainer>
            <TabButton
              title={t("All time")}
              selected={allDataTab}
              onClick={() => setAllDataTab(true)}
            />
            <TabButton
              title={`${cleanUpDateTime(chart.chartParams.x1.toISOString())} -${" "}
              ${cleanUpDateTime(chart.chartParams.x2.toISOString())} ${t("range")}`}
              selected={!allDataTab}
              onClick={() => setAllDataTab(false)}
            />
          </TabsContainer>
          <ContentBox>
            <EnergyTable
              co2={chart.installation.co2}
              tableData={allDataTab ? chart.allEnergyData : chart.energyData}
            />
          </ContentBox>
        </>
      }
    </>
  );
}
