import React from "react";

import { useTranslation } from "react-i18next";

import { NodeInfoHeader } from "./NodeInfoHeader";
import { DeviceNode } from "../../types/types";
import { SensorRow } from "./SensorRow";

import style from "./NodeInfo.module.scss";
import { useChart } from "../../contexts/ChartDataContext";
import { NodeTypes, TSensorTypes } from "../../types/generated_types";
import { NodeSignalStrength } from "./NodeSignalStrength";
import { PowerButton } from "./PowerButton";
import { CloudControlButton } from "./CloudControlButton";
import { ScheduleButton } from "./TimeRangeModal/ScheduleButton";

interface Props {
  node: DeviceNode;
}

export function NodeInfo({
  node,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const chart = useChart();
  const { updateNodeName, updateSensorColor, sensorCheckboxToggle } = useChart();
  const archived = !!chart.installation.archived_time;

  const hasError = (
    (node.node_type === NodeTypes.IMI || node.node_type === NodeTypes.AD9) && 
    (node.water_bucket_full || node.red_alarm)
  ) || (
    node.node_type === NodeTypes.IMSMk2 &&
    node.probe_fault
  );

  const isActive = node.active && !archived

  return (
    <div
      className={style.node}
      data-active={isActive}
      data-error={hasError}
    >
      <div
        className={style["status-bar"]}
        data-active={isActive}
        data-error={hasError}
      >
        <span className={style["status-text"]}> {isActive ? t("Active") : t("Passive")}</span>
        {isActive && <NodeSignalStrength node={node} />}

        <div className={style["controls-box"]}>
          { node.node_type === NodeTypes.AD9 && !archived &&
            <>
              <CloudControlButton node={node}/>
              <div className={style.separator}/> 
            </>
          }
          { (node.node_type === NodeTypes.IMI || node.node_type === NodeTypes.AD9) && 
            !archived &&
            <PowerButton
              isOn={node.state_is_on || false}
              outlet={TSensorTypes.STE}  // the single (non-numbered) "outlet"
              node={node}
            />
          }
          { (node.node_type === NodeTypes.IMRMk2
              || node.node_type === NodeTypes.CC4
              || node.node_type === NodeTypes.IMI
              || node.node_type === NodeTypes.AD9
            ) &&
            <ScheduleButton
              isScheduled={node.quiet_times.length > 0}
              node={node}
            />
          }
        </div>
      </div>
      <div
        className={style.contents}
      >
        <NodeInfoHeader
          node={node}
          updateSensorName={(newName: string) => updateNodeName(node.id, newName)}
        />
        {node.latest_data === null ? (
          <div className={style["no-data"]}>
            <div>({t("NO DATA")})</div>
          </div>
        ) : (
          node.fields.map((sensor) => {
            if (
              node.node_type === NodeTypes.AD9 &&
              node.model?.endsWith('S') &&
              sensor.sens_type === "SPEED"
            ) {
              return (
                null
              );
            }
            else if (
              node.node_type === NodeTypes.AD9 &&
              !node.model?.endsWith('S') &&
              sensor.sens_type === "HEAT"
            ) {
              return (
                null
              );
            }
            else {
              return (
                <SensorRow
                  dbId={node.id}
                  sensor={sensor}
                  node={node}
                  key={sensor.front_end_name}
                  onCheckboxChange={(e) =>
                    sensorCheckboxToggle(
                      node.id,
                      e.target.checked,
                      sensor.sens_type,
                    )
                  }
                  onSensorColorChange={(color, sensorType) =>
                    updateSensorColor(node.id, color, sensorType)
                  }
                  isArchived={archived}
                  latestData={sensor.latest_data}
                />
              );
            }
          })
        )}
      </div>
    </div>
  );
}
