import React from "react"
import style from "./BackToListButton.module.scss"
import { Link } from "react-router-dom"

interface Props {
  archive: boolean
}

export function BackToListButton({
  archive,
}: Props): React.ReactElement {

  return <Link
    to={archive ? "/dashboard/list/archive" : "/dashboard/list/active"}
    type="button"
    className={style.button}
  >
    <i className="fas fa-arrow-left" />
  </Link>
}