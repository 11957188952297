import React, { useState } from "react";
import style from "./ExpandableButtonContainer.module.scss";

import expandIcon from "../images/expand.svg";
import collapseIcon from "../images/collapse.svg";
import { Button } from "../components/Misc/Buttons/Button";
import { ExpandableContainer } from "../components/Misc/ExpandableContainer";

interface Props {
  children: React.ReactNode,
  title: string,
}

export function ExpandableButtonContainer({
  children,
  title,
}: Props): React.ReactElement {
  const [visible, setVisible] = useState<boolean>(false);

  function toggleVisability(): void {
    setVisible(!visible);
  }

  return (
    <div
      className={style["expandable-button-container"]}
      data-expanded={visible}
    >
      <Button
        onClick={toggleVisability}
      >
        <div className={style.title}>{title}</div>
        <div className={style["expandable-icon-container"]}>
          <img src={visible ? collapseIcon : expandIcon} alt="" />
        </div>
      </Button>
      <ExpandableContainer show={visible}>
        <div className={style["content-wrapper"]}>
          {children}
        </div>
      </ExpandableContainer>
    </div>
  );
}
