import React, { useState } from "react";
import { GearButton } from "./IconButton/GearButton";
import { PortalModal } from "../PortalModal/PortalModal";
import PortalModalHeader from "../PortalModal/PortalModalHeader";
import { useTranslation } from "react-i18next";
import PortalModalContent from "../PortalModal/PortalModalContent";
import PortalModalFooter from "../PortalModal/PortalModalFooter";
import { DeviceNodeAD9 } from "../../types/types";
import { Requester } from "../../utils/Requester";
import { useChart } from "../../contexts/ChartDataContext";
import { SelectorRow } from "../PortalModal/SelectorRow";
import { CloseButton } from "../PortalModal/CloseButton";
import { ButtonStyle, ModalButton } from "../PortalModal/ModalButton";
import toast from "react-hot-toast";
import { WarningRow } from "../PortalModal/WarningRow";
import { getProductName } from "../../utils/utility";
import { SamplingRateUpdateMessage } from "./SamplingRateUpdateMessage";

interface Props {
  node: DeviceNodeAD9
  disabled: boolean
}

export function FanCConfigButton({
  node,
  disabled,
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const chart = useChart();
  const samplingRate = useChart().installation.sampling_rate;

  const { t } = useTranslation()

  return <>
    <GearButton
      onClick={() => {setShowModal(true)}}
      disabled={disabled}
      title={t("Configure fan mode")}
    />

    <PortalModal
      isOpen={showModal}
      close={() => setShowModal(false)}
    >
      <PortalModalHeader
        title={node.fanc ? t("Disable continuous fan mode") : t("Enable continuous fan mode")}
        close={() => setShowModal(false)}
      />
      <PortalModalContent narrow>
        <SelectorRow label={t("Current state")}>
          {node.fanc ? t("ON") : t("OFF")}
        </SelectorRow>
        <br/>
        { node.active ?
          <SamplingRateUpdateMessage
            samplingRate={samplingRate}
          />
          :
          <WarningRow>
            {getProductName(node.node_type, t)} {t("is currently")} <strong>{t("passive")}</strong>.
            <br />
            {t("The action will be scheduled to happen when it comes online.")}
          </WarningRow>
        }
      </PortalModalContent>
      <PortalModalFooter>
        <ModalButton
          onClick={() => {
            Requester.setNodeControlConfig({
              ino_pk: node.id,
              ohtr: node.ohtr,
              fanc: !node.fanc, // toggle continuous fan mode
              slow_fan: node.slow_fan,
            })
            .then(() => {
              setShowModal(false);
              toast.success(t("Saved").toString());
              chart.refreshNodes();
            })
            .catch(() => toast.error(t("Something went wrong").toString()));
          }}
          buttonStyle={node.fanc ? ButtonStyle.RED : ButtonStyle.GREEN}
        >
          {node.fanc ? t("Disable") : t("Enable")}
        </ModalButton>
        <CloseButton close={() => setShowModal(false)} />
      </PortalModalFooter>
    </PortalModal>
  </>
}