import React, { ReactNode } from "react";

import styles from "./InfoBubble.module.scss";

interface Props {
  children: ReactNode;
  battery?: boolean;
}

export function InfoBubble({
  children,
  battery,
}: Props) {
  const iconStyle = ["fas"];
  if (battery) {
    iconStyle.push("fa-battery-quarter");
    iconStyle.push(styles.battery);
  } else {
    iconStyle.push("fa-info-circle");
  }

  return <div className={styles["component-wrapper"]}>
    <i className={iconStyle.join(" ")}/>
    <div className={styles["tooltip-wrapper"]}>
      <div className={styles.tooltip}>
        {children}
      </div>
      <div className={styles.triangle}/>
    </div>
  </div>
}