import { Trans, TFunction, useTranslation } from "react-i18next"
import React from "react"
import Select from "react-select"
import { DeviceNode, Materials } from "../../types/types"

import styles from "./MCRow.module.scss"
import { useChart } from "../../contexts/ChartDataContext"
import { Requester } from "../../utils/Requester"
import toast from "react-hot-toast"
import { NodeBoxButton } from "../Misc/Buttons/NodeBoxButton"

interface Props {
  node: DeviceNode
}

function getMaterialName(material: Materials, t: TFunction<string>) : string {
  /* NOTE: Maintain together with back-end's MATERIAL_NAMES
   *       in backend/reports/helpers.py
   */
  switch (material) {
    case Materials.BRIC:
      return t("Brick")
    case Materials.CEME:
      return t("Cement mortar")
    case Materials.CONC:
      return t("Concrete")
    case Materials.PLAS:
      return t("Plaster")
    case Materials.SAND:
      return t("Sand and cement screed")
    case Materials.WOOD:
      return t("Wood")
  }
}

export function MCRow({
  node,
}: Props) {
  const { t } = useTranslation()
  const { materialMap, updateMaterial } = useChart()

  return <>
    <p className={styles.label}><Trans>Select material:</Trans></p>
    <div className={styles.end}>
      <Select
        menuPlacement="auto"
        styles={{
          control: (provided) => ({
            ...provided,
            height: "24px",
            width: "12rem",
            minHeight: "24px",
            alignContent: "center",
          }),
        }}
        value={{
          value: materialMap.get(node.local_id),
          label: getMaterialName(materialMap.get(node.local_id)!, t),
        }}
        options={Object.values(Materials).map(key => ({
          value: key,
          label: getMaterialName(key as Materials, t),
        }))}
        onChange={item => {
          // Ensure `item` is present and is non-array
          // TODO: Newer versions should infer type better (IMCS-519)
          if (item && "value" in item)
            updateMaterial(node.local_id, item.value!)
        }}
      />
      <NodeBoxButton
        title={t("Save")}
        onClick={() =>
          Requester.postSensorMaterial({
            instNodeId: node.id,
            newMaterial: materialMap.get(node.local_id)!
          })
          .then(response => {
            // TODO: Remove if condition with its code when catch block not triggering issue is resolved
            if ("error" in response)
              throw response["error"]
            toast.success(t("Material saved").toString())
          })
          .catch(error => toast.error(error))
        }
      >
        <i className="fas fa-save"></i>
      </NodeBoxButton>
    </div>
  </>
}
