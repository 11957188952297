import React, { ReactElement, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DeviceNode } from "../../types/types";
import {
  validateNewName,
  updateSensorNameWrapper,
  revertChanges,
} from "../../containers/NodeInfoHeader.helper";

import style from "./NodeInfoHeader.module.scss";
import { getProductName, getProbeName } from "../../utils/utility";
import { InfoBubble } from "../Misc/InfoBubble";
import { NodeTypes, ProbeTypes, TSensorTypes } from "../../types/generated_types";
import { NodeBoxButton } from "../Misc/Buttons/NodeBoxButton";
import { BannerStyle, InfoBanner } from "../Misc/InfoBanner";

interface Props {
  node: DeviceNode;
  updateSensorName: (newName: string, nodeID: string) => void;
}

function acclimatiseEstimate(startDate : string): number {
  // captured_datetime (startDate) needs to explicitly cast into Date
  const acclimationLocalTime = new Date(startDate);
  return Math.ceil((Date.now() - acclimationLocalTime.getTime()) / 3600000);
}

export function NodeInfoHeader({
  node,
  updateSensorName,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [nameEditOnState, setNameEditOnState] = useState(false);
  const [displayErrorState, setDisplayErrorState] = useState(false);
  const [newNameState, setNewNameState] = useState("");

  useEffect(() => {
    setNewNameState(node.sensor_name);
  }, [nameEditOnState, node.sensor_name]);

  // Determine wether to display a form for changing the sensors name or just
  // display the sensors name
  const SensorName = nameEditOnState ? (
    <>
      <input
        type="text"
        value={newNameState}
        className="new-sensor-name"
        placeholder={t("New Sensor Name")}
        aria-label="Recipient's username"
        name="sensorName"
        onChange={(e) => {
          if (validateNewName(e.target.value)) {
            setDisplayErrorState(false);
          } else {
            setDisplayErrorState(true);
          }
          setNewNameState(e.target.value);
        }}
      />
      <NodeBoxButton
        title={t("Save settings")}
        aria-hidden="true"
        onClick={() =>
          updateSensorNameWrapper(
            node,
            newNameState,
            setNameEditOnState,
            displayErrorState,
            updateSensorName,
          )
        }
      >
        <i className="fas fa-save"/>
      </NodeBoxButton>
      <NodeBoxButton
        aria-hidden="true"
        onClick={() =>
          revertChanges(
            setNameEditOnState,
            setDisplayErrorState,
            setNewNameState,
          )
        }
        title={t("Revert Changes")}
      >
        <i className="fas fa-times" />
      </NodeBoxButton>
    </>
  ) : (
    <>
      {node.sensor_name}
      <i
        className={`fas fa-pen ${style["edit-name-button"]}`}
        onClick={() => setNameEditOnState(true)}
      />
    </>
  );

  const resistance = Number.parseFloat(
    node.fields.find(item => item.sens_type === TSensorTypes.OHM)?.latest_data ?? ""
  );

  // Store various node status notifications
  const message = [];
  // Probe fault, calibrating or acclimatising status
  if (node.node_type === NodeTypes.IMSMk2) {
    if (node.probe_fault) {
      message.push(<p>{t("Probe error")}</p>);
    } else if (
      node.probe_type === ProbeTypes.VOC &&
      (node.calibrating || node.acclimatising)
    ) {
      //const estimate = node.calibrating ? 48 : acclimatiseEstimate(node.acclimatising)
      // Inverted logic because of typescript issues
      const estimate = node.acclimatising ? acclimatiseEstimate(node.acclimatising) : 48
      message.push(
        <div>
          {t("Acclimatising")}
          <InfoBubble>
            <div style={{ fontWeight: "normal", width: "13rem", textAlign: "left"}}>
              <p style={{ marginBottom: "0.5em"}}>
                <Trans>
                Data will be available after a <b>48h</b> acclimatisation process
                </Trans>
              </p>
              {node.active &&
                <p>
                  <Trans count={estimate} i18nKey="_acclimatisation_estimate">
                  Estimated remaining time: <b>{{count: estimate}}{"\u00a0"}hours</b>
                  </Trans>
                </p>
              }
            </div>
          </InfoBubble>
        </div>
      );
    }
  }

  // Show resistance out of range warning when resistance is <10kΩ or >1GΩ
  if (resistance < 0.01 || resistance > 1000) {
    message.push(
        <div>
          {t("Resistance out of range")}
          <InfoBubble>
            <div style={{fontWeight: "normal", width: "13rem", textAlign: "left"}}>
              <Trans i18nKey="_resistance_fault_info">
                <p style={{marginBottom: "0.5em"}}>
                  Normal working range is <b>0.01&nbsp;MΩ to 1000&nbsp;MΩ.</b> Possible
                  faults may include:
                </p>
                <p style={{marginBottom: "0.5em"}}>
                  <b>Low impedance</b> fault<br/>
                  Probe in contact with water, metal or salts.
                </p>
                <p>
                  <b>High impedance</b> fault<br/>
                  Probe not inserted or improperly inserted into material.
                </p>
              </Trans>
            </div>
          </InfoBubble>
        </div>
      );
  }
  // Full water bucket message and red alam (displayed in single line)
  if (node.node_type === NodeTypes.AD9 || node.node_type === NodeTypes.IMI)
  if (node.water_bucket_full || node.red_alarm) {
    const combinedMessage = [];
    if (node.water_bucket_full) combinedMessage.push(t("Water bucket full"));
    if (node.red_alarm) combinedMessage.push(t("Technical alarm"));
    message.push(<p>{combinedMessage.join(", ")}</p>);
  }

  const errorStyle = (
    (node.node_type === NodeTypes.IMI || node.node_type === NodeTypes.AD9) &&
    (node.water_bucket_full || node.red_alarm)
  ) || (
    node.node_type === NodeTypes.IMSMk2 &&
    node.probe_fault
  );

  return (
    <>
      { message.length > 0 &&
        <InfoBanner
          style={errorStyle ? BannerStyle.ERROR : BannerStyle.WARNING}
          small
        >
          {message}
        </InfoBanner>
      }
      { (displayErrorState && nameEditOnState) &&
        <InfoBanner style={BannerStyle.ERROR} small>
          {t("Length : min 1, max 25")}
        </InfoBanner>
      }
      <div className={style.header}>
        <div className={style.row}>
          <div className={style.label}>{t("Name")}</div>
          <div className={style["name-box"]}>{SensorName}</div>
        </div>
        <div className={style.row}>
          <div className={style.label}>{t("Type")}</div>
          <div>{getProductName(node.node_type, t)}</div>
        </div>
        { node.node_type === NodeTypes.AD9 &&
          <div className={style.row}>
            <div className={style.label}>{t("Model")}</div>
            <div>{node.model !== null ? node.model : t("Unknown")}</div>
          </div>
        }
        { node.node_type === NodeTypes.IMSMk2 &&
          <div className={style.row}>
            <div className={style.label}>{t("Probe type")}</div>
            <div>{getProbeName(node.probe_type, t)}</div>
          </div>
        }
        <div>
          <div className={style.row}>
            <div className={style.label}>{t("ID")}</div>
            <div>{node.local_id}</div>
          </div>
        </div>
      </div>
    </>
  );
}
