/* eslint-disable import/no-default-export */
import React, { useMemo, useState } from "react";
import { InstallationVersions } from "../types/types";
import { BackToListButton } from "../components/Misc/BackToListButton";
import { ChartDataManager } from "./ChartDataManager";
import { NodeInfoManager } from "../components/NodeInfo/NodeInfoManager";
import { formatDate } from "./SensorDataContainer.helper";

import style from "./SensorData.module.scss";
import { useChart } from "../contexts/ChartDataContext";
import { PortalModal } from "../components/PortalModal/PortalModal";
import { InstallationConfigModal } from "../components/SensorData/InstallationConfigModal";

import { PageTitleBox } from "../components/Misc/PageTitleBox";
import { Button } from "../components/Misc/Buttons/Button";


export default function SensorDataContainer(): React.ReactElement {
  const [showInstallationConfigModal, setShowInstallationConfigModal] = useState(false);
  
  const chart = useChart();

  const { nodes } = useChart();

  const selectedRangeFormatted = useMemo(
    () =>
      `${formatDate(chart.chartParams.x1)}-${formatDate(chart.chartParams.x2)}`,
    [chart.chartParams.x1, chart.chartParams.x2],
  );

  return (
    <>
      <PageTitleBox
        rightContainer={chart.installation.version === InstallationVersions.FOUR
          ? <Button
              onClick={() => setShowInstallationConfigModal(true)}
            >
              <i className="fas fa-gear" />
            </Button>
          : <></>
        }
      >
        <BackToListButton archive={chart.installation.archived_time != null} />
        <div>
          <h4>
            {chart.installation.name} (ID:&nbsp;{chart.device_id})
          </h4>
          <p>
            [&nbsp;{selectedRangeFormatted}&nbsp;]
          </p>
        </div>
      </PageTitleBox>
      <ChartDataManager />
      <div className={style["node-info-container"]}>
        <NodeInfoManager
            nodes={nodes}
          />
      </div>
      <PortalModal
        isOpen={showInstallationConfigModal}
        close={() => setShowInstallationConfigModal(false)}
      >
        <InstallationConfigModal 
          device_id={chart.device_id}
          installation={chart.installation}
          onUpdate={chart.refreshInstallationInfo}
          close={() => setShowInstallationConfigModal(false)}
        />
      </PortalModal>
    </>
  );
}
