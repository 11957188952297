import React, { ReactNode } from "react"
import styles from "./TabLink.module.scss"

import { Link, useRouteMatch } from "react-router-dom"

interface Props {
  children?: ReactNode
  path: string
}
/**
 * Navigation link styled as a tab. It must be placed within `<TabsContainer>`
 * which provides styling to ensure a seamless background between the selected
 * tab and the container below.
 */
export function TabLink({
  children,
  path,
}: Props) {
  const match = useRouteMatch(path)
  return <Link to={path}>
    <div
      className={styles.tab}
      data-selected={match !== null}
    >
      {children}
    </div>
  </Link>
}