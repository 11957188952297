import React, { MouseEventHandler, ReactNode } from "react"
import style from "./NodeBoxButton.module.scss"

export interface NodeBoxButtonProps {
  children: ReactNode
  title?: string
  disabled?: boolean
  onClick?: MouseEventHandler

  actionBarStyle?: boolean
  onOffStyle?: boolean
  on?: boolean
}

export function NodeBoxButton({
  children,
  title,
  disabled,
  onClick,
  actionBarStyle,
  onOffStyle,
  on,
}: NodeBoxButtonProps) {
  return <button
    className={style.button}
    title={title}
    disabled={disabled}
    onClick={onClick}
    data-action-bar={actionBarStyle}
    data-on-off={onOffStyle}
    data-on={on}
  >
    {children}
  </button>
}