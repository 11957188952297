import React, {
  createContext,
  useState,
  ReactChild,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";

import { useRouteMatch } from "react-router-dom";
import { SpinnyThingy } from "../components/Misc/SpinnyThingy";
import { useChartGetParams } from "../hooks/UseChartGetParams";
import {
  ChartConfig,
  ChartGetParamsStatic,
  ChartTypes,
  DeviceNode,
  InstallationInfo,
  Materials,
  EnergyData,
  SelectedNodeSensors,
  STESubSensors,
  QuietTime,
} from "../types/types";
import { Requester } from "../utils/Requester";
import { NodeTypes, ProbeTypes, TSensorTypes } from "../types/generated_types";
import { EnergySavingPattern, NodeEnergySavingData } from "../components/EnergySaving/EnergySavingView";

interface Props {
  children: ReactChild;
}

export interface ChartDataContextInterface {
  installation: InstallationInfo;
  device_id: string;
  nodes: Array<DeviceNode>;
  noNodesAvailable: boolean;
  hasData: boolean;
  setHasData: (val: boolean) => void;
  chartParams: ChartGetParamsStatic;
  energySavingData: Array<NodeEnergySavingData>
  energyData: Array<EnergyData>;
  allEnergyData: Array<EnergyData>;
  selSensorTypes: Array<TSensorTypes>;
  secondaryAxis: TSensorTypes | undefined;
  yMaxMain: number | undefined;
  yMinMain: number | undefined;
  yMaxSecond: number | undefined;
  yMinSecond: number | undefined;
  materialMap: Map<string, Materials>;
  updateEnergySavingPattern: (nodeId: number, pattern: EnergySavingPattern, socketId: number) => void;
  refreshNodes: () => void;
  reloadNodes: () => void;
  refreshQuietTimes : (node: DeviceNode, quiet_times: QuietTime[]) => void;
  updateChartConfig: (newChartParams: ChartConfig) => void;
  updateChartType: (newChartType: ChartTypes) => void;
  updateNodeName: (installationNodeId: number, newName: string) => void;
  updateRelayLabel: (nodeId: number, localId: number, newLabel: string) => void;
  updateSensorColor: (
    installationNodeId: number,
    newColour: string,
    sensor: TSensorTypes,
  ) => void;
  nodePowerStateToggle: (
    installationNodeId: number,
    newState: boolean,
    sensor: STESubSensors,
  ) => void;
  sensorCheckboxToggle: (
    installationNodeId: number,
    newState: boolean,
    sensor: TSensorTypes,
  ) => void;
  toggleAllSensorTypes: (checkbox: Array<TSensorTypes>, state: boolean) => void;
  updateLinearTime: (newLinearTimeState: boolean) => void;
  loadDataRange: (newX1: Date, newX2: Date, allDataBool: boolean, datesAreVisible: boolean) => void;
  refreshInstallationInfo: () => void,
  updateMaterial: (id: string, material: Materials) => void,
}

export const ChartDataContext = createContext<ChartDataContextInterface>(
  // A proxy object allows to completely define the behavior of a proxied object
  // In this case if a parent component wont be wrapped in ChartDataContextProvider
  // Then any calls made to access the context values will result in
  // Being cached by the proxy object and display the errors bellow
  new Proxy({} as ChartDataContextInterface, {
    apply: () => {
      throw new Error(
        "You must wrap your component in an ChartDataContextProvider",
      );
    },
    get: () => {
      throw new Error(
        "You must wrap your component in an ChartDataContextProvider",
      );
    },
  }),
);

export interface InstallationRouteMatch {
  id?: string;
}

function ChartDataContextProvider({
  children,
}: Props): React.ReactElement {
  const match = useRouteMatch<InstallationRouteMatch>();
  const [installation, setInstallation] = useState<InstallationInfo>();
  const [deviceID, setDeviceID] = useState<string>("");
  const [energyData, setEnergyData] = useState<Array<EnergyData>>([]);
  const [allEnergyData, setAllEnergyData] = useState<Array<EnergyData>>([]);
  const [chartParams, setParams] = useChartGetParams();
  const [activeNodes, setActiveNodes] = useState<Array<DeviceNode>>([]);
  const [energySavingData, setEnergySavingData] = useState<Array<NodeEnergySavingData>>([]);
  // Required below state to send information about nodes avaibility 
  // after getting installation nodes request is finished, else
  // a refactor is necessary to let the child component know this info.
  const [noNodesAvailable, setNoNodesAvailable] = useState<boolean>(false);
  const [hasData, setHasData] = useState<boolean>(true);
  const [loadingState, setLoadingState] = useState(true);
  const [reload, setReload] = useState(false);
  const [materialMap, setMaterialMap] = useState<Map<string, Materials>>(new Map());

  // Introduced along with refreshNodes depencency (commented in relevant useEffect) to avoid
  // a rerender loop
  const paramsId = useRef<String | null>(null);

  useEffect(() => {
    if (installation) {
      void Requester.getPwrUsageData({
        id: installation.pk,
        endDate: chartParams.x2,
        startDate: chartParams.x1,
      }).then((res) => {
        setEnergyData(res.data);
      });
    }
  }, [installation, chartParams.x1, chartParams.x2]);


  useEffect(() => {
    if (installation) {
      void Requester.getInstallMaxRange({
        installationID: installation.pk,
      }).then((max_min_time) => {
        void Requester.getPwrUsageData({
          id: installation.pk,
          endDate: new Date(max_min_time.data.max_time),
          startDate: new Date(max_min_time.data.min_time),
        }).then((pwrData) => {
          setAllEnergyData(pwrData.data);
        });
      });
    }
  }, [installation]);

  const refreshNodes = useCallback(async () => {
    // Fetch & Update locally stored nodes
    let typesRequest: SelectedNodeSensors = {};
    if (Object.keys(chartParams.nodes).length <= 0) {
      typesRequest = await Requester.getDefaultSelectedSensors(
        Number(match.params.id),
      );
    } else {
      typesRequest = chartParams.nodes;
    }

    void Requester.getInstallNodes({
      installationID: Number(match.params.id),
    }).then((e) => {
      if (
        Object.keys(chartParams.nodes).length <= 0
        && Object.keys(typesRequest).length <= 0
      ) {
        // determine default sensor types that will be selected for all nodes in chart view
        let hasRH = false;
        let hasOHM = false;
        let hasVOC = false;
        e.data.forEach(node => {
          if (node.node_type === NodeTypes.IMSMk2) {
            if (!hasRH) hasRH = node.probe_type === ProbeTypes.RH;
            if (!hasOHM) hasOHM = node.probe_type === ProbeTypes.OHM;
            if (!hasVOC) hasVOC = node.probe_type === ProbeTypes.VOC;
          }
        });

        let selectedTypes = [TSensorTypes.HUM]; // default selection
        if (hasRH && hasOHM && hasVOC)
          selectedTypes = [TSensorTypes.HUM, TSensorTypes.WME];
        else if (hasRH && hasVOC)
          selectedTypes = [TSensorTypes.HUM];
        else if (hasRH && hasOHM)
          selectedTypes = [TSensorTypes.HUM, TSensorTypes.WME];
        else if (hasVOC && hasOHM)
          selectedTypes = [TSensorTypes.IAQ];
        // else if (hasRH) same as default
        else if (hasVOC) selectedTypes = [TSensorTypes.IAQ];
        else if (hasOHM) selectedTypes = [TSensorTypes.WME];

        e.data.forEach((node) => {
          typesRequest[node.id] = selectedTypes;
        });
      }
      const newMaterialMap = new Map();
      const sortedNodes = e.data
        .sort((a, b) => Number(a.local_id) - Number(b.local_id))
        .map((node) => {
          // Toggle the enabled sensors
          const newNode = node;
          if (Object.keys(typesRequest).includes(node.id.toString())) {
            const fields = node.fields.map(sensor => ({
              ...sensor,
              enabled: typesRequest[node.id].includes(sensor.sens_type),
            }));
            newNode.fields = fields;
          }
          if (
            node.node_type === NodeTypes.IMSMk2 &&
            node.probe_type === ProbeTypes.OHM && 
            node.material
          ) {
            newMaterialMap.set(node.local_id, node.material);
          }
          return newNode;
        });
      setActiveNodes(sortedNodes);
      setNoNodesAvailable(sortedNodes.length === 0);
      setMaterialMap(newMaterialMap);
    });
  }, [chartParams.nodes, match.params.id]);

  const reloadNodes = () => {
    setParams({nodes: {}});
    setReload(true);
  }

  useEffect(() => {
    if (reload && Object.keys(chartParams.nodes).length <= 0) {
      refreshNodes();
    }
  }, [chartParams.nodes, reload, refreshNodes]);

  const refreshQuietTimes = useCallback((node: DeviceNode, quiet_times: QuietTime[]) => {
    setActiveNodes((prev) => prev.map(
      (e) => (e.id === node.id) ? {...e, quiet_times: quiet_times} : e
    ));
  }, []);

  useEffect(() => {
    // paramsId used to avoid rerender loop caused by addition of refreshNodes dependency in
    // 994649f
    if (match.params.id && paramsId.current !== match.params.id) {
      paramsId.current = match.params.id;
      setLoadingState(true);
      // Get installation info
      void Requester.getInstallationInfo({
        installationID: Number(match.params.id),
      })
        .then((data) => {
          setInstallation(data.installations);
          setDeviceID(data.devices.device_id);
        })
        .then(() => refreshNodes())
        .then(() => setLoadingState(false));
    }
  }, [match.params.id, refreshNodes]);

  useEffect(() => {
    Requester.getEnergySaving({ installationID: Number(match.params.id) })
      .then(response => {
        if (response.data) setEnergySavingData(response.data);
        else throw response;
      })
      .catch(() => { })
  }, [match.params.id]);

  // Update URL nodes
  useEffect(() => {
    const selectedNodes: SelectedNodeSensors = {};
    activeNodes.forEach((e) => {
      selectedNodes[e.id] = e.fields
        .filter((s) => s.enabled)
        .map((s) => s.sens_type);
    });
    setParams({ nodes: selectedNodes });
  }, [activeNodes, setParams]);

  // ----------------- PUBLIC INTERFACE ----------------- //
  const updateEnergySavingPattern = useCallback(
    (nodeId: number, pattern: EnergySavingPattern, socketId: number) => {
      setEnergySavingData(oldData => oldData.map(el => {
        if (el.instNodeID === nodeId) {
          var patternsList = el.patterns;
          patternsList[socketId-1] = pattern;
          return { ...el, patterns: patternsList}
        } else {
          return el;
        }
      }));  //oldArray => [...oldArray.filter((e) => e.instNodeID !== nodeId), updatedNodeData])
    }, [setEnergySavingData]);

  const updateNodeName = useCallback(
    (installationNodeId: number, newName: string) => {
      void Requester.postNodeName({
        id: installationNodeId,
        newName,
      }).then(() => refreshNodes());
    },
    [refreshNodes],
  );
  const updateRelayLabel = useCallback(
    (nodeId: number, localId: number, newLabel: string) => {
      void Requester.postRelayLabel({
        id: nodeId,
        localId,
        newLabel,
      }).then(() => refreshNodes());
    },
    [refreshNodes],
  );
  const updateSensorColor = useCallback(
    (installationNodeId: number, newColor: string, sensor: TSensorTypes) => {
      void Requester.postSensorColor({
        newColor,
        nodeId: installationNodeId,
        type: sensor,
      }).then(() => refreshNodes());
    },
    [refreshNodes],
  );
  const nodePowerStateToggle = useCallback(
    (installationNodeId: number, newState: boolean, sensor: STESubSensors) => {
      void Requester.postToggleSensorPowerState({
        id: installationNodeId,
        sensor_type: sensor,
        state: newState,
      }).then(() => refreshNodes());
    },
    [refreshNodes],
  );
  const sensorCheckboxToggle = useCallback(
    (installationNodeId: number, newState: boolean, sensor: TSensorTypes) => {
      const newNodes = activeNodes.map((e) => {
        if (e.id === installationNodeId) {
          return {
            ...e,
            fields: e.fields.map((f) => {
              if (f.sens_type === sensor) {
                return { ...f, enabled: newState };
              }
              return f;
            }),
          };
        }
        return e;
      });
      setActiveNodes(newNodes);
    },
    [activeNodes],
  );
  const toggleAllSensorTypes = useCallback(
    (sensorType: Array<TSensorTypes>, state: boolean) => {
      const newNodes = activeNodes.map((node) => {
        const fields = node.fields.map((f) => {
          if (sensorType.includes(f.sens_type)) {
            return { ...f, enabled: state };
          }
          return f;
        });
        return { ...node, fields };
      });
      setActiveNodes(newNodes);
    },
    [activeNodes],
  );

  const updateChartConfig = useCallback(
    (newConfig: ChartConfig) => {
      setParams(newConfig);
    },
    [setParams],
  );

  const updateChartType = useCallback(
    (newChartType: ChartTypes) => {
      setParams({ chartType: newChartType });
    },
    [setParams],
  );

  const updateLinearTime = useCallback(
    (newLinearTimeState: boolean) => {
      setParams({ linearData: newLinearTimeState });
    },
    [setParams],
  );

  const loadDataRange = useCallback(
    (newX1: Date, newX2: Date, allDataBool: boolean, visibleDates: boolean) => {
      setParams({ x1: newX1, x2: newX2, allData: allDataBool, visibleDates });
    },
    [setParams],
  );

  const refreshInstallationInfo = useCallback(() => {
    void Requester.getInstallationInfo({
      installationID: Number(match.params.id),
    }).then((data) => {
      setInstallation(data.installations);
      setDeviceID(data.devices.device_id);
    });
  }, [match.params.id]);

  const updateMaterial = useCallback(
    (nodeID: string, material: Materials) => {
      let map = new Map(materialMap);
      map.set(nodeID, material);
      setMaterialMap(map);
  }, [materialMap, setMaterialMap]);

  // Used for selecting the secondary valueAxis sensor type
  // and displaying types next to the chart
  const selSensorTypes = useMemo(() => {
    const tempTypes: TSensorTypes[] = [];
    if (activeNodes.length !== 0) {
      activeNodes.forEach((node) => {
        node.fields.forEach((sens) => {
          if (sens.enabled) {
            tempTypes.push(sens.sens_type);
          }
        });
      });

      // Incase selected types change and the secondary axis type is no longer selected
      // or theres only one sensor type in the array, set it to undefined
      if (chartParams.secondaryAxis && (tempTypes.indexOf(chartParams.secondaryAxis) <= -1 || tempTypes.length <= 1)) {
        setParams({ secondaryAxis: undefined });
      }
    }
    return [...Array.from(new Set(tempTypes))]; // Removes duplicates
  }, [activeNodes, chartParams.secondaryAxis, setParams]);

  if (!installation) return <SpinnyThingy />;

  return (
    <ChartDataContext.Provider
      value={{
        yMaxMain: chartParams.yMaxMain,
        yMinMain: chartParams.yMinMain,
        yMaxSecond: chartParams.yMaxSecond,
        yMinSecond: chartParams.yMinSecond,
        materialMap,
        installation,
        device_id: deviceID,
        nodes: activeNodes,
        energySavingData,
        noNodesAvailable,
        chartParams,
        selSensorTypes,
        secondaryAxis: chartParams.secondaryAxis,
        hasData,
        setHasData,
        updateEnergySavingPattern,
        refreshNodes,
        reloadNodes,
        refreshQuietTimes,
        updateNodeName,
        updateRelayLabel,
        updateSensorColor,
        nodePowerStateToggle,
        sensorCheckboxToggle,
        toggleAllSensorTypes,
        updateChartConfig,
        updateChartType,
        updateLinearTime,
        loadDataRange,
        updateMaterial,
        energyData,
        refreshInstallationInfo,
        allEnergyData,
      }}
    >
      {loadingState && <SpinnyThingy />}
      {!loadingState && children}
    </ChartDataContext.Provider>
  );
}

const useChart = (): ChartDataContextInterface =>
  React.useContext(ChartDataContext);

export { ChartDataContextProvider, useChart };
