import React, { MouseEventHandler, ReactNode } from "react"
import style from "./Button.module.scss"

interface Props {
  children: ReactNode
  disabled?: boolean
  onClick: MouseEventHandler
}

export function Button({
  children,
  disabled,
  onClick,
}: Props) {
  return <button
    className={style.button}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
}