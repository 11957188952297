import React, { ReactNode } from "react"
import style from "./FilterBlock.module.scss"

interface Props {
  children: ReactNode
  label: string
}

export function FilterBlock({
  children,
  label,
}: Props) {
  return <div className={style.block}>
    <label>{label}</label>
    {children}
  </div>
}